import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Hero from '../components/hero'
import MainContent from '../components/mainContent'
import Post from '../components/postItems'

export default class TagTemplate extends PureComponent {
  render () {
    const author = this.props.data.contentfulAuthor
    const { name, posts, picture, cover, bio } = author
    return (
      <Layout location={this.props.location}>
        <Helmet>
          <title>{name}</title>
        </Helmet>
        <Hero backgroundImageSrc={cover && cover.fluid.src}>
          <Img fixed={picture.fixed} alt='Author image' className='avatar' />
          <h1 className='tag-name h4'>{name}</h1>
          <div className='post-count'>{posts.length} post</div>
          <div className='col-md-8 offset-md-2'>
            <div
              className='author-bio'
              dangerouslySetInnerHTML={{ __html: bio.childMarkdownRemark.html }}
            />
          </div>
          {/* <div className='col-md-8 offset-md-2'>
            <ul className='meta-info text-center'>
              <li>
                <a href='https://twitter.com/gbjsolution' target='_blank'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <path d='M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z' />
                  </svg>
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/gbjsolution' target='_blank'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <path d='M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z' />
                  </svg>
                </a>
              </li>
              <li>
                <a href='http://gbjsolution.com' target='_blank'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  >
                    <path d='M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z' />
                  </svg>
                </a>
              </li>
              <li className='location'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M12 1c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 14h-24l4-8h3.135c.385.641.798 1.309 1.232 2h-3.131l-2 4h17.527l-2-4h-3.131c.435-.691.848-1.359 1.232-2h3.136l4 8z' />
                </svg>
                India
              </li>
            </ul>
          </div> */}
        </Hero>
        <MainContent>
          <Post.List>
            {posts.map(post => (
              <Post.ListItem key={post.id} post={post} />
            ))}
          </Post.List>
        </MainContent>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ContentfulAuthorBySlug($slug: String!) {
    contentfulAuthor(slug: { eq: $slug }) {
      name
      slug
      bio {
        childMarkdownRemark {
          html
        }
      }
      cover {
        ...HeroBackgroundImage
      }
      picture {
        fixed(quality: 85, width: 120, height: 120, cropFocus: FACES) {
          ...GatsbyContentfulFixed
        }
      }
      posts: post {
        id
        title
        slug
        createdAt
        body {
          childMarkdownRemark {
            timeToRead
          }
        }
        author {
          slug
          name
        }
        tags {
          id
          name: title
          slug
        }
        featuredImage {
          fluid(quality: 85, maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
